import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import Particles from 'react-particles-js'
import Highlight, { defaultProps } from 'prism-react-renderer'
import highlightTheme from 'prism-react-renderer/themes/nightOwl'

import { RODO, Layout, Wrapper, Subline, SEO, PrevNext, Menu } from '../components'
import { t, currentLanguage } from '../t/t'

const components = {
  pre: (props) => (
    <Highlight {...defaultProps} theme={highlightTheme} code={props.children.props.children} language="jsx">
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  ),
}

const Content = styled.article`
  grid-column: 2;
  /* box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1); */
  max-width: 800px;
  border-radius: 1rem;
  padding: 2rem 4.5rem;
  background-color: ${(props) => props.theme.colors.bg};
  z-index: 9000;
  margin-top: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  p {
    font-size: 1.1rem;
    letter-spacing: -0.003em;
    line-height: 1.58;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
      font-size: 1rem;
    }
  }

  .prism-code {
    padding: 0.75rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-size: 16px;
  }
`

const Title = styled.h1`
  margin-bottom: 1rem;
`

const PostContent = styled.div`
  margin-top: 4rem;
`

const Separator = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Post = ({ pageContext: { slug, prev, next }, data: { mdx: postNode } }) => {
  const post = postNode.frontmatter

  return (
    <Layout customSEO>
      <Menu />
      <RODO />
      <Particles
        height={100}
        params={{
          particles: {
            number: {
              value: 160,
              density: {
                enable: true,
                value_area: 400,
              },
            },
            color: {
              value: '#4a4a4a',
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0,
                sync: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: true,
                speed: 4,
                size_min: 0.3,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: '#4a4a4a',
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 0.5,
              direction: 'none',
              random: true,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 600,
              },
            },
          },
          retina_detect: true,
        }}
      />
      <Wrapper>
        <SEO postPath={slug} postNode={postNode} article />
        <Content>
          <Title>{post.title}</Title>
          <Subline>
            {post.date} &mdash; {postNode.timeToRead} min czytania &mdash; w{' '}
            {post.categories.map((cat, i) => (
              <React.Fragment key={cat}>
                {!!i && t[currentLanguage].menu[cat] && ', '}
                <Link to={`/categories/${kebabCase(cat)}`}>{t[currentLanguage].menu[cat]}</Link>
              </React.Fragment>
            ))}
          </Subline>
          <PostContent>
            <MDXProvider components={components}>
              <MDXRenderer>{postNode.body}</MDXRenderer>
            </MDXProvider>
          </PostContent>
          <Separator>
            <Subline>Patryk Szczygło</Subline>
            <Subline>
              Programista w Netguru. Bloger od 2017 roku. Miłośnik podróży, książek i elektroniki. Stworzył własny
              blockchain w JavaScript. Marzy o automatyzacji i robotyce w życiu.
            </Subline>
          </Separator>
          <PrevNext prev={prev} next={next} />
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Post

Post.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.object,
    prev: PropTypes.object,
  }),
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired,
  }).isRequired,
}

Post.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
}

export const postQuery = graphql`
  query postBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        date(formatString: "MM/DD/YYYY")
        categories
      }
      timeToRead
      parent {
        ... on File {
          mtime
          birthtime
        }
      }
    }
  }
`
